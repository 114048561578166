// App imports
import { IsoDropdown } from './iso';
import { FiltersDropdown } from './type';
import './styles.scss';

// Parameters imports
import { transportListOfValues, minutesDict, roomsDict } from './properties';

// Context imports
import { useIsochroneApi } from 'context/api/isochrone';
import { usePropertyType } from 'context/filters/property';

export const MapHeader = () => {
	const { routingProfile, setRoutingProfile, contoursMinutes, setContoursMinutes } = useIsochroneApi();
	const { rooms, setRooms } = usePropertyType();

	return (
		<div className="map-header-wrapper">
			<div></div>
			<div className="map-header">
				<IsoDropdown
					listOfValues = {transportListOfValues}
					currentState={routingProfile}
					setState={setRoutingProfile}
				/>
				<FiltersDropdown
					imoveisDict={minutesDict}
					propertyName={`${contoursMinutes} min`}
					setPropertyTypeId={setContoursMinutes}
				/>
				<FiltersDropdown
					imoveisDict={roomsDict}
					propertyName={`${rooms ? rooms : ""} rooms`}
					setPropertyTypeId={setRooms}
				/>
			</div>
			<div></div>
		</div>
	)
}

MapHeader.displayName="MapHeader";