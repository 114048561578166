// React imports
import { useState, useEffect, useMemo, useContext, createContext } from 'react';

// Context imports
import { useMapbox } from '../mapbox';
import { useIsochroneApi } from 'context/api/isochrone';
import { useDates } from 'context/filters/dates';
import { usePrices } from 'context/filters/prices';
import { useLinesLimits } from 'context/limits/lines';

// Third-party imports
import * as turf from '@turf/turf';

const MaskContext: React.Context<any> = createContext(null)

export const useMask = () => {
	return (
		useContext(MaskContext)
	)
}

export const MaskProvider = ({children}: any) => {
	const { mapRef } = useMapbox();
	const { isochroneData } = useIsochroneApi();
	const { formatedStartDate, formatedFinalDate } = useDates();
	const { leftPosition, rightPosition } = usePrices();
	const { bottomLimit, topLimit } = useLinesLimits();

	const [ mapFeatures, setMapFeatures ] = useState([]);
	const [ activeFeatures, setActiveFeatures ] = useState(false);

	useEffect(() => {
		const map = mapRef.current;
		if (!map) return;
		const onData = (e: any) => e.tile && setActiveFeatures((prev) => !prev);
	    map.on('data', onData);
	    return () => {map.off('data', onData)};
	}, [ mapRef.current ]);

	useEffect(() => {
		const map = mapRef.current;
		if (!map) return;
		const features = map.queryRenderedFeatures();
		setMapFeatures(features);
	}, [ activeFeatures, mapRef.current ]);

	const maskProperties = useMemo(() => {
	    return mapFeatures.filter((item: any) => {
	        if (item.source === 'airbnb-points' && isochroneData) {
	        	const isochroneGeometry = isochroneData.features[0].geometry;
	            return turf.booleanPointInPolygon(item.geometry, isochroneGeometry);
	        }
	    return false
	    });
	}, [ mapFeatures, isochroneData ]);


	const features = maskProperties && maskProperties.reduce((baseGeometries: any, maskProp: any) => {
		let { geometry, properties } = maskProp;
		const { price, last_scraped } = properties;
	    if (
	        leftPosition < price &&
	        price < rightPosition &&
	        formatedStartDate < new Date(last_scraped) &&
	        new Date(last_scraped) < formatedFinalDate
	    ) {

		    baseGeometries.push({
		      type: 'Feature',
		      geometry: {
		        type: 'Point',
		        coordinates: geometry.coordinates,
		      },
		      properties: properties,
		    });
		}
		return baseGeometries
	}, [])

	const filteredPoints: any = features.reduce((total: any, item: any) => {
		const price = item.properties.price;

		if (price >= topLimit) {
	        total.high.push(item);
	    } else if (price < bottomLimit) {
	        total.min.push(item);
	    } else {
	        total.mean.push(item);
	    }
		return total
	}, { high: [], min: [], mean: [] });

	// Normalize filteredPoints to ensure exactly 100 items
	const totalFeatures = features.length;
	const totalBalls = 48;

	// Calculate proportional counts for each category
	const highCount = Math.round((filteredPoints.high.length / totalFeatures) * totalBalls);
	const minCount = Math.round((filteredPoints.min.length / totalFeatures) * totalBalls);
	const meanCount = totalBalls - highCount - minCount; // Adjust for rounding errors

	// Create the normalized filteredPoints object
	const classifiedPoints: any = {
	    high: highCount,
	    min: minCount,
	    mean: meanCount,
	};

	// Create the normalized filteredPoints object
	const clusterPoints: any = {
	    high: filteredPoints.high,
	    min: filteredPoints.min,
	    mean: filteredPoints.mean,
	};

	const colors: any = {
	    "high": 'rgba(153, 51, 255, 1)',
	    "mean": 'rgba(67, 181, 64, 1)',
	    "min":  'rgba(200, 0, 0, 1)'
	}

	return (
		<MaskContext.Provider value={{ features, classifiedPoints, clusterPoints, colors }}>
			{children}
		</MaskContext.Provider>
	)
}

MaskContext.displayName = "MaskContext";