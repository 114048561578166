// App imports
import { Dots } from './dots';
import { Gauge } from './gauge';
import { Legend } from './legend';
import { Clusters } from './clusters';
import './styles.scss';

export const Right = () => {
	return (
		<div className="right-wrapper">
			<div className="right-item-wrapper">
				<div className="right-item-title">Clusters</div>
				<Clusters/>	
			</div>
			<div className="right-item-wrapper">
				<div className="right-item-title">Dots</div>
				<Dots/>
			</div>
			<div className="right-item-wrapper">
				<div className="right-item-title">Gauge</div>
				<div className="gauge-graphics-wrapper">
					<Legend/>
					<Gauge/>
				</div>
			</div>
		</div>
	)
}

Right.displayName="Right";