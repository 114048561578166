// App imports
import { Prices } from './prices';
import { Timeseries } from './timeseries';
import { SvgMap } from './svgMap';
import './styles.scss';

// Context imports
import { useIsochroneApi } from 'context/api/isochrone';
import { usePricesApi } from 'context/api/imoveis/prices';

export const Left = () => {
	const { initialMarker } = useIsochroneApi();
	const { pricesData } = usePricesApi();

	return (
		<div className="left">
			<div className="airbnb-message-wrapper">
				{!initialMarker && pricesData &&
					<div className="airbnb-sidebar-items">
						<SvgMap/>
						<Prices pricesData={pricesData}/>
						<Timeseries/>
					</div>
				}
			</div>
		</div>
	)
}

Left.displayName="Left";