// Third-party imports
import { Source, Layer } from 'react-map-gl';

export const Clustered = ({ id, currentCluster, color }: any) => {
	const pointsSource: any = {
		id: `${id}Source`,
		type: 'geojson',
	    data: {
		    type: 'FeatureCollection',
		    features: currentCluster.map((d: any) => ({
				type: 'Feature',
				geometry: {
					type: 'Point',
					coordinates: d.geometry.coordinates,
				},
				properties: {
					cluster: false
				},
			})),
		}
	}

	const pointsLayer: any = {
	    id: id,
	    type: 'circle',
	    source: `${id}Source`,
	    paint: {
		'circle-radius': [
            'interpolate',
            ['linear'],
            ['get', 'point_count'],
            1, 5,
            50, 7,
            100, 10,
            500, 14,
        ],
	      'circle-color': color,
	    },
	  };

	return (
		<Source 
			{...pointsSource}
			cluster={true}
			clusterMaxZoom={14}
			clusterRadius={50}
		>
			<Layer {...pointsLayer}/>
		</Source>
	)
}