// App imports
import { Clustered } from './clustered'

// Context imports
import { useMask } from 'context/maps/mask';

export const Points = () => {
	const { clusterPoints, colors } = useMask();
	
	if (!clusterPoints) return <></>

	return (
		<>
			{Object.keys(clusterPoints).map((item: any) => {
				return (
					<Clustered 
						key={item}
						id={item}
						currentCluster={clusterPoints[item]} 
						color={colors[item]}
					/>
				)
			})}
		</>
	)
}