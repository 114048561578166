// App imports
import { PointsProvider } from './points';
import { TooltipProvider } from './tooltip';
import { MapboxProvider } from './mapbox';
import { StylesProvider } from './styles';
import { MaskProvider } from './mask';
import { EventsProvider } from './events';

export const MapsProvider = ({children}: any) => {
  return (
    <MapboxProvider>
    <TooltipProvider>
    <MaskProvider>
    <PointsProvider>
    <StylesProvider>
    <EventsProvider>
      {children}
    </EventsProvider>
    </StylesProvider>
    </PointsProvider>
    </MaskProvider>
    </TooltipProvider>
    </MapboxProvider>
  )
}

MapsProvider.displayName="MapsProvider";