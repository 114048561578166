// App imports
import './styles.scss';

// Context imports
import { useMask } from 'context/maps/mask';

export const Legend = ({ roomsData }: any) => {
	const { clusterPoints, colors } = useMask();
	const sumOfValues: any = Object.values(clusterPoints).reduce(
        (sum: any, arr: any) => sum + arr.length, 0
    );

	return (
		<div className="rooms-legend-wrapper">
			{
				Object.keys(clusterPoints).map((item: any, index: number) => {
					const backgroundColor = colors[item];
					const currentPercent = (clusterPoints[item].length / sumOfValues) * 100;

					if (!currentPercent) return <></>

					return (
						<div key={index}>
							<div className="rooms-legend-item-wrapper">
								<div
									className="rooms-legend-text"
									style={{color: "rgba(255, 255, 255, 1)"}}
								>
									{item}
								</div>
								<div
									className="rooms-legend-item"
									style={{backgroundColor: backgroundColor}}
								>
								</div>
								<div
									className="rooms-legend-text"
									style={{color: "rgba(255, 255, 255, 1)"}}
								>
									{Math.round(currentPercent)}%
								</div>
							</div>
					</div>
				)
			})}
		</div>
	)
}

Legend.displayName="Legend";