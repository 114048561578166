// React imports
import { useState, useEffect, useRef, useCallback } from 'react';

// App imports
import './styles.scss';

// Context imports
import { useMask } from 'context/maps/mask';

export const Dots = () => {
    const { classifiedPoints, colors } = useMask();
    const [ containerSize, setContainerSize ] = useState<any>({ width: 0, height: 0 });
    const [ gridConfig, setGridConfig ] = useState({ columns: 0, dotSize: 0 });

    const parentRef = useCallback((node: any) => {
        const updateSize = () => {
            if (node) {
                const { width, height } = node.getBoundingClientRect();
                setContainerSize({ width, height });
            }
        };
        updateSize();
        window.addEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
    if (containerSize.width && containerSize.height) {
      const dotSize = Math.min(containerSize.width, containerSize.height) / 9; // Adjust divider for size
      const columns = Math.floor(containerSize.width / dotSize);
      setGridConfig({ columns, dotSize });
    }
  }, [containerSize]);

    const sumOfValues: any = Object.values(classifiedPoints).reduce(
        (sum: any, arr: any) => sum + arr.length, 0
    );

	return (
		<div className="right-component-wrapper" ref={parentRef}>
            <div className="balls-wrapper">
    			<div 
                    className="balls"
                    style={{ width: containerSize, height: containerSize }}
                >
    				{Object.keys(classifiedPoints).map((item: string) => {
                        const backgroundColor = colors[item];
                        const count = classifiedPoints[item];

                        return Array.from({ length: count }, (_, index) => (
                            <div
                                key={`${item}-${index}`}
                                style={{
                                  backgroundColor,
                                  borderRadius: '50%',
                                  width: gridConfig.dotSize,
                                  height: gridConfig.dotSize,
                                }}
                            />
                        ));
                    })}
    			</div>
            </div>
		</div>
	)
}

Dots.displayName="Dots";