// React imports
import { useMemo, useContext, createContext } from 'react';

// Context imports
import { useTooltip } from '../tooltip';
import { usePropertyType } from 'context/filters/property';
import { useLinesLimits } from 'context/limits/lines';
import { useMask } from 'context/maps/mask';

// Third party imports
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import { GridCellLayer } from 'deck.gl';

const PointsContext: React.Context<any> = createContext(null)

export const usePoints = () => {
	return (
		useContext(PointsContext)
	)
}

export const PointsProvider = ({children}: any) => {
	const { bottomLimit, topLimit } = useLinesLimits();
	const { setPropertyHoverInfo, setPropertyInfo, setActivePropertyInfo } = useTooltip();
	
	// Data
	const { features } = useMask();
	
	// Filters
	const { currentPropertyId, setCurrentPropertyId } = usePropertyType();
	
  	const onClick = (info: any) => {
  		setActivePropertyInfo(true);
  		info.object && setPropertyInfo(info.object);
  	};

  	const onHover = (info: any) => {
  		info.object && setCurrentPropertyId(info.object.properties.id);
  		!info.object && setCurrentPropertyId(null);
  		setPropertyHoverInfo(info);
  	}

  	const selectedColor = [255, 255, 0, 255];
  	const maxDataBar = [ 130, 100, 200, 200 ];
  	const meanDataBar = [ 0, 200, 0, 200 ];
  	const minDataBar = [ 255, 70, 70, 200 ];

	const pointsLayer = features &&
		new GridCellLayer({
		    id: 'prices-layer',
		    data: features,
		    cellSize: 10,
		    pickable: true,
		    getPosition: (d: any) => d.geometry.coordinates,
		    material: false,
			getElevation: (d: any) =>  d.properties.price,
		    getFillColor: (d: any) => 
		    	currentPropertyId && currentPropertyId === d.property_id ?
		    	selectedColor :
		    	d.properties['price'] > topLimit ?
		    	maxDataBar :
		    	d.properties['price'] < bottomLimit ?
		    	minDataBar :
		    	meanDataBar,
		    // updateTriggers: {getFillColor: [currentPropertyId]},
		    // onHover,
		    // onClick: (info: any) => onClick(info),
		  });
	return (
		<PointsContext.Provider value={{ pointsLayer }}>
			{children}
		</PointsContext.Provider>
	)
}

PointsContext.displayName = "PointsContext";