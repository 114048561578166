// App imports
import { Logo } from './logo';
import { Search } from './search';
import { SaveFile } from './saveFile';
import './styles.scss';

export const Header = () => {
	return (
		<div className="main-header">
			<div className="normal-header">
				<Logo/>
			</div>
			<div></div>
			<Search/>
			<SaveFile/>
		</div>
	)
}

Header.displayName="Header"