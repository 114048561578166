// App imports
import './styles.scss';

// Context imports
import { useListing } from 'context/filters/listing';
 
export const SaveFile = ({ inputRef }: any) => {
	const { printDocument, activeListing, setActiveListing } = useListing();

	return (
		<>
			{
				!activeListing ? 
				<svg 
					className="download-arrow"
					width="24"
					height="24"
					onClick={ setActiveListing }
				>
					<path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" fill="rgba(255, 56, 92, 1)"/>
				</svg>
				 : 
				<svg 
					className="download-arrow"
					width="24"
					height="24"
					onClick={printDocument}
				>
					<path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/>
				</svg>
			}
		</>
	)
}

SaveFile.displayName="SaveFile"