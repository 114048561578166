// React imports
import { useCallback } from 'react';

// Context imports
import { useGeo } from 'context/geo';

// Third-party imports
import { Marker } from 'react-map-gl';

export const Pin = () => {
	const { marker, setMarker } = useGeo();
	const { latitude, longitude } = marker;

	const onMarkerDrag = useCallback((event: any) => {
		const { lng, lat } = event.lngLat;
		setMarker({longitude: lng, latitude: lat});
	}, []);

	return (
		<Marker
	      longitude={longitude}
	      latitude={latitude}
	      anchor="bottom"
	      draggable
	      onDrag={onMarkerDrag}
	    >
	      <img 
		      style={{width: "15px"}} 
		      src="static/components/maps/marker.svg" 
		      alt="marker"
	     />
	    </Marker>
	)
}

Pin.displayName="Pin";