// React imports
import { useCallback } from 'react';

// App imports
import { Pin } from './pin';
import { Points } from './points';
import { Isochrone } from './isochrone';
import './styles.scss';

// Context imports
import { useMapbox } from 'context/maps/mapbox';
import { useGeo } from 'context/geo';
import { useIsochroneApi } from 'context/api/isochrone';

// Third-party imports
import { Map } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

export const Clusters = () => {
	const { pdfMapRef, currentBaseMap } = useMapbox();
	const { viewport, setMarker, setPlaceCoordinates } = useGeo();
	const { setInitialMarker } = useIsochroneApi();

	const onDblClick = useCallback((event: any) => {
		const { lng, lat } = event.lngLat;
		setInitialMarker(false);
		setPlaceCoordinates({ longitude: lng, latitude: lat });
		setMarker({ longitude: lng, latitude: lat });
	}, []);

	return (
		<div style={{position: "relative"}}>
		<Map
			ref={pdfMapRef}
			initialViewState={{...viewport, bearing: 0, pitch: 0, zoom: 11}}
			mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
			mapStyle={currentBaseMap}
			onDblClick={onDblClick}
			doubleClickZoom={false}
			antialias={true}
			preserveDrawingBuffer={true}
		>
			<Isochrone/>
			<Points/>
			<Pin/>
		</Map>
		</div>
	)
}

Clusters.displayName="Clusters";