// App imports
import { PropertyTypeProvider } from './property';
import { DatesProvider } from './dates';
import { PricesProvider } from './prices';
import { ListingProvider } from './listing';

export const FiltersProvider = ({children}: any) => {
  return (
    <PropertyTypeProvider>
    <DatesProvider>
    <PricesProvider>
    <ListingProvider>
      {children}
    </ListingProvider>
    </PricesProvider>
    </DatesProvider>
    </PropertyTypeProvider>
  )
}

FiltersProvider.displayName="FiltersProvider";