export const transportListOfValues: any = {
	"walking": "static/components/maps/header/walking-active.svg",
	"cycling": "static/components/maps/header/cycling-active.svg",
	"driving": "static/components/maps/header/driving-active.svg"
}

export const minutesDict: any = {
	"5": "5 min",
	"10": "10 min",
	"15": "15 min",
	"30": "30 min",
	"60": "60 min",
}

export const roomsDict: any = {
	"1": "1 room",
	"2": "2 rooms",
	"3": "3 rooms",
	"4": "4 rooms",
	"all": "show all",
}