// React imports
import { useState, useRef, useContext, createContext } from 'react';

const ListingContext: React.Context<any> = createContext(null)

export const useListing = () => {
	return (
		useContext(ListingContext)
	)
}

export const ListingProvider = ({children}: any) => {
	const [ activeListing, setActiveListing ] = useState(false);

	return (
		<ListingContext.Provider value={{ 
			activeListing, setActiveListing 
		}}>
			{children}
		</ListingContext.Provider>
	)
}

ListingContext.displayName = "ListingContext";