// App Imports
import { Table } from './table';
import { Footer } from './footer';
import './styles.scss';

// Context imports
import { useListing } from 'context/filters/listing';

export const Listing = () => {
	const { activeListing, setActiveListing } = useListing();

	return (
		<>
			{activeListing && 
				<div className="listing-wrapper" onClick={() => setActiveListing(false)}>
					<div className="listing" onClick={(e: any) => e.stopPropagation()}>
						<div className="listing-body">
							<Table/>
							<Footer/>
						</div>
					</div>
				</div>
			}
		</>
	)
}

Listing.displayName="Listing";