// App imports
import { Price } from './price';
import { Symbols } from './symbols';
import './styles.scss';

// Context imports
import { useTooltip } from '../../../../context/maps/tooltip';

export const Tooltip = () => {
	const { propertyHoverInfo } = useTooltip(); 
	
	if (!propertyHoverInfo || !propertyHoverInfo.object) return <></>

	const properties = propertyHoverInfo.object.properties;

	return (
		<div 
			className="tooltip-wrapper" 
			style={{ left: propertyHoverInfo.x, top: propertyHoverInfo.y }}
		>
			<div className="tooltip-header">
				<Price price={properties.price} propertyHoverInfo={propertyHoverInfo}/>
				<Symbols item={properties}/>
			</div>
			<img 
				width={170}
				src={properties.picture_url}
				alt="property"
			/>
		</div>
	)
}

Tooltip.displayName="Tooltip";