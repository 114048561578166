// Context imports
import { useTooltip } from 'context/maps/tooltip';
import { useLinesLimits } from 'context/limits/lines';
import { usePricesLimits } from 'context/limits/prices';

export const Points = ({ linesData, xScale, yScale }: any) => {
    const { propertyHoverInfo } = useTooltip();
    const { bottomLimit, topLimit } = useLinesLimits();
    const { filterPrices } = usePricesLimits();

    const currentPropertyId = propertyHoverInfo && propertyHoverInfo.object && propertyHoverInfo.object.property_id;
    
    return (
        filterPrices.map((item: any, i: number) => {
            return (
                <circle 
                    key={i}
                    cx={xScale(Date.parse(item.start_date))} 
                    cy={yScale(item['price'])}
                    r={
                        currentPropertyId === item.property_id ? 5 : 
                        1.6
                    }
                    fill={
                        currentPropertyId === item.property_id ? 
                        "rgba(222, 222, 0, 1)" :
                        item['price'] > topLimit ?
                        "rgba(153, 51, 255, 1)" :
                        item['price'] < bottomLimit?
                        "rgba(200, 0, 0, 1)" : 
                        "rgba(67, 181, 64, 1)"
                    }
                    stroke={"none"}
                />
            )
        }
    )
    
)}
